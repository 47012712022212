import React from "react";
import LeftSection from "./LeftSection";

const MainContact = () => {
  return (
    <div className=" mb-24">
      <LeftSection />
    </div>
  );
};
export default MainContact;
