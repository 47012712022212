import React from "react";
// import Goa from "./../../Assets/Goa.png";
// import Kashmir from "./../../Assets/Kashmir.png";

const Section1 = () => {
  return (
    <div className="">
      <h5 className="text-3xl text-cyan-400 text-center font-semibold">
        Go & Discover
      </h5>
      <h2 className="xl:text-7xl text-center sm:text-3xl font-bold">
        Breathtaking Cities
      </h2>
      <p className="text-xl mt-6 text-center">
        Let's make your journey memorable. Contact us and start exploring!
      </p>
    </div>
  );
};

export default Section1;
