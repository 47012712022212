import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";

export const LINKS = [
  { name: "Home", link: "#" },
  { name: "About us", link: "#" },
  { name: " Our Hotels", link: "#" },
  { name: "  Contact Us", link: "#" },
];
export const TOURS = [
  { name: "Romantic France", link: "#" },
  { name: "Beach Goa", link: "#" },
  { name: "Awesome Amsterdam", link: "#" },
  { name: "Wild Africa", link: "#" },
  { name: "Casino Las Vegas", link: "#" },
];
export const SERVICES = [
  { name: "Domestic flights", link: "#" },
  { name: "International Flights", link: "#" },
  { name: "Tours & Holidays", link: "#" },
  { name: "Domestic Hotels", link: "#" },
  { name: "International Hotels", link: "#" },
  { name: "Cruise Holidays", link: "#" },
];
export const SUPPORT = [
  { name: "📞 9899359708", link: "#" },
  { name: "📞9999683737", link: "#" },
  { name: "📞 9971220855", link: "#" },
  { name: "📧 sales.travok@gmail.com", link: "#" },
  { name: "📧 tarsh@travok.net", link: "#" },
];

export const Icons = [
  { name: <FaFacebook />, link: "#" },
  { name: <FaTwitter />, link: "#" },
  { name: <FaInstagramSquare />, link: "#" },
];
