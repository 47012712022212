const PlaceImages = [
  {
    url: require("./../Assets/Hotel1.png"),
    name: "Goa",
    alt: "Goa",
  },
  {
    url: require("./../Assets/Hotel2.png"),
    name: "Goa",
    alt: "Jaisalmer",
  },
  {
    url: require("./../Assets/Hotel3.png"),
    name: "Manali",
    alt: "Manali",
  },
  {
    url: require("./../Assets/Hotel4.png"),
    name: "Mussoorie",
    alt: "Mussoorie",
  },
  {
    url: require("./../Assets/Hotel5.png"),
    name: "Shimla",
    alt: "Shimla",
    decs: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.",
  },
  {
    url: require("./../Assets/Hotel6.png"),
    name: "Dubai",
    alt: "Dubai",
    decs: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.",
  },
  {
    url: require("./../Assets/Hotel7.png"),
    name: "Singapore",
    alt: "Singapore",
    decs: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.",
  },
  {
    url: require("./../Assets/Hotel8.png"),
    name: "Paris",
    alt: "Paris",
  },
];
export default PlaceImages;
