import React from "react";
import Section1 from "./Section1";
// import Section2 from "./Section2";

const BeautifulDestination = () => {
  return (
    <div className=" py-8 px-6">
      <Section1 />
      {/* <Section2 /> */}
    </div>
  );
};

export default BeautifulDestination;
